<div *ngIf="!driver" class="text-center public-responsive">
    <h1>Enter Driver's Info</h1>
    <br />
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <form class="form-inline form-contents" [formGroup]="driverInfoForm" (ngSubmit)="onSubmit()">
                <div>
                    <div>
                        <label for="phone">Phone Number:</label>
                        <input type="text" id="phone" formControlName="phone" required>
                        <div *ngIf="driverInfoForm.get('phone').errors?.required && driverInfoForm.get('phone').touched"
                            class="error-message">
                            Phone Number is required.
                        </div>
                        <div *ngIf="driverInfoForm.get('phone').errors?.forbiddenNumber && driverInfoForm.get('phone').touched"
                            class="error-message">
                            Phone Number must be 10 digits long & can only contain numerical characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="lastName">Last Name:</label>
                        <input type="text" id="lastName" formControlName="lastName" required>
                        <div *ngIf="driverInfoForm.get('lastName').errors?.required && driverInfoForm.get('lastName').touched"
                            class="error-message">
                            Last Name is required.
                        </div>
                        <div *ngIf="driverInfoForm.get('lastName').errors?.forbiddenName && driverInfoForm.get('lastName').touched"
                            class="error-message">
                            Last Name cannot contain numbers or special characters.
                        </div>
                    </div>
                    <br>
                </div>
            </form>
            <button (click)="onSubmit()" type="submit" class="btn btn-primary" [disabled]="driverInfoForm.invalid">{{submitButtonText}}</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <span class="new-driver-link--wrapper">
                <div class="new-driver-link">New Driver? Please <a [routerLink]="['/public', 'driver-check-in-new']">click here</a> to enter your info.
                </div>
            </span>
        </div>
    </div>
    <br />
</div>

<div *ngIf="driver" class="text-center public-responsive">
    <h1>Welcome {{driver.FirstName | titlecase }} {{driver.LastName | titlecase }}!</h1>
    <p class="f-20">Would you like to check in or edit your profile?</p>
    <br />
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <button type="button" class="btn btn-primary" (click)="goToDriverCheckInPage()">Continue</button>
        </div>
        <div class="col-md-6 col-sm-12">
            <button type="button" class="btn btn-primary" (click)="goToDriverEditPage()">Edit my Info</button>
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver"></app-driver-check-in-support-button>
    <br />
</div>
