import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IOrderPartial } from '../../../model/partials/order-partial';
import { PublicCheckInDTO } from '../../../model/interfaces/custom/public-check-in-dto';
import { ValidOrderReturnDTO } from '../../../model/interfaces/custom/valid-order-return.dto';
import { IUser } from '../../../model/interfaces/user';
import { FindValidDriverDTO } from '../../../model/interfaces/custom/find-valid-driver-dto';
import { ICreateUserPayload } from '../../../model/interfaces/custom/create-user-payload';
import { IPickup } from '../../../model/interfaces/pickup';
import { TwicImageTypes } from '../../../model/TwicImageTypes';
import { UpdateUserPicResult } from '../../../model/interfaces/custom/update-user-pic-result.dto';
import { ILocation } from '../../../model/interfaces/location';
import { ICheckIn } from '../../../model/interfaces/check-in';
import { ICheckInDetail } from '../../../model/interfaces/check-in-detail';
import { IOrder } from '../../../model/interfaces/order';
import { IPickupParams } from '../../../model/interfaces/custom/pickup-params';
import { IWalkInPickupParams } from '../../../model/interfaces/custom/walk-in-pickup-params';
import { ImageResponse } from '../../../model/interfaces/image-response';
import { OrderLimits } from '../../../model/classes/order-limits';
import { PickupStatus } from '../../../orders/order-basic-info/pickup-status-enum';
import { IDelivery } from '../../../model/interfaces/delivery';

export const EmptyCheckInData: PublicCheckInDTO = {
    carrierId: null,
    checkInTypeId: null,
    driversLicense: null,
    firstName: null,
    lastName: null,
    licensePlate: null,
    phone: null,
    validOrders: [],
};

export const EmptyOrder: IOrderPartial = {
    CustomerId: null,
    OrderId: null,
    OrderNumber: null,
};

@Injectable()
export class PublicCheckInService {
    checkInData: BehaviorSubject<PublicCheckInDTO> = new BehaviorSubject<PublicCheckInDTO>(EmptyCheckInData);

    updateCheckInData(value: PublicCheckInDTO): void {
        this.checkInData.next(value);
    }

    getEmptyOrder(): IOrderPartial {
        return { ...EmptyOrder };
    }

    constructor(public http: HttpClient) {}

    searchValidOrder(orderNumber: string): Observable<ValidOrderReturnDTO> {
        return this.http.get<ValidOrderReturnDTO>(`/public-check-in/find-valid-order/${orderNumber}`);
    }

    submitPublicCheckIn(checkInData: PublicCheckInDTO): Observable<number> {
        return this.http.post<number>(`/public-check-in`, checkInData);
    }

    findValidDriver(dto: FindValidDriverDTO): Observable<IUser> {
        return this.http.post<IUser>(`/public-check-in/find-valid-driver`, dto);
    }

    findValidDriverById(id: number): Observable<IUser> {
        return this.http.get<IUser>(`/public-check-in/find-valid-driver/${id}`);
    }

    createDriver(data: ICreateUserPayload): Observable<IUser> {
        return this.http.post<IUser>(`/public-check-in/create-driver`, data);
    }

    editDriver(user: IUser): Observable<IUser> {
        return this.http.post<IUser>(`/public-check-in/edit-driver`, user);
    }

    findValidPickup(pickupNumber: string): Observable<IPickup> {
        return this.http.get<IPickup>(`/public-check-in/find-valid-pickup/${pickupNumber}`);
    }

    getOrdersForPickup(orderNumber: string): Observable<IOrder[]> {
        return this.http.get<IOrder[]>(`/public-check-in/get-orders-for-pickup/${orderNumber}`);
    }

    saveTwicImage(driverId: number, photo: File, twicImageType: TwicImageTypes): Observable<UpdateUserPicResult> {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post<UpdateUserPicResult>(`/public-check-in/${driverId}/twic/${twicImageType}/image`, formData);
    }

    deleteTwicImage(driverId: number, twicImageType: TwicImageTypes): Observable<object> {
        return this.http.delete(`/public-check-in/${driverId}/twic/${twicImageType}/image`);
    }

    setDriverTwicCardNumber(driverId: number, twicCardNumber: string): Observable<IUser> {
        return this.http.post<IUser>(`/public-check-in/${driverId}/set-driver-twic-card-number/${twicCardNumber}`, null);
    }

    getPickupLocation(driverId: number, pickupId: number): Observable<ILocation> {
        return this.http.get<ILocation>(`/public-check-in/driver/${driverId}/pickup/${pickupId}/location`);
    }

    createCheckInForDriver(driverId: number, pickupId: number): Observable<ICheckIn> {
        return this.http.post<ICheckIn>(`/public-check-in/driver/${driverId}/pickup/${pickupId}/create-check-in`, null);
    }

    sendDriverNotifiedSms(driverId: number, pickupId: number): Observable<IPickup> {
        return this.http.post<IPickup>(`/public-check-in/driver/${driverId}/pickup/${pickupId}/send-driver-notified-sms`, null);
    }

    getHelp(driverId: number, pickupId: number | null): Observable<boolean> {
        return this.http.post<boolean>(`/public-check-in/driver/${driverId}/pickup/${pickupId}/get-help`, null);
    }

    getCheckInDetailDoors(checkInId: number): Observable<ICheckInDetail[]> {
        return this.http.get<ICheckInDetail[]>(`/public-check-in/check-in-detail-doors/${checkInId}`);
    }

    getPickupById(id: number): Observable<IPickup> {
        return this.http.get<IPickup>(`/public-check-in/pickups/${id}`);
    }

    getDriverSearchTimes(orderIds: number[], startDate: Date, endDate: Date, officeId: number, driverId: number): Observable<Date[]> {
        return this.http.post<Date[]>(`/public-check-in/driver/${driverId}/searchtimes`, {
            EndDate: endDate,
            OfficeId: officeId,
            OrderIds: orderIds,
            StartDate: startDate,
        });
    }

    setPickupDateTime(pickupId: number, dateRequested: Date, driverId: number, carrierId: number): Observable<IPickup> {
        return this.http.post<IPickup>(`/public-check-in/set-pickup-date-time`, {
            DriverId: driverId,
            DateRequested: dateRequested,
            PickupId: pickupId,
            CarrierId: carrierId,
        });
    }

    createDriverPickup(orders: IOrder[], dateRequested: Date, carrierId: number, driverId: number): Observable<IPickup>{
        const params: IPickupParams = { orders, dateRequested, carrierId, driverId, deliveryZipCode: '', dateConfirmed: null, statusId: PickupStatus.Requested };
        return this.http.put<IPickup>(`/public-check-in/driver/${driverId}/create-pickup`, params);
    }

    createWalkInPickup(pickupParams: IWalkInPickupParams[], driverId: number): Observable<IPickup>{
        return this.http.post<IPickup>(`/public-check-in/driver/${driverId}/create-walk-in-pickup`, pickupParams);
    }

    getGatePassBarcode(driverId: number, pickupId: number): Observable<ImageResponse> {
        return this.http.get<ImageResponse>(`/public-check-in/driver/${driverId}/pickup/${pickupId}/gate-pass-barcode`);
    }

    getCheckInFee(checkInId: number): Observable<number> {
        return this.http.get<number>(`/public-check-in/check-in/${checkInId}/fee`);
    }

    getRemainingBalance(pickupId: number): Observable<number> {
        return this.http.get<number>(`/public-check-in/pickup/${pickupId}/remaining-balance`);
    }

    getDriverOrders(officeId: number): Observable<IOrder[]> {
        return this.http.get<IOrder[]>(`/public-check-in/office/${officeId}/driver-orders`);
    }

    getOrderLimits(): Observable<OrderLimits> {
        return this.http.get<OrderLimits>(`/public-check-in/order-limits`);
    }

    assignOrdersToPickup(pickupId: number, driverId: number, orders: IOrder[]): Observable<IPickup> {
        return this.http.post<IPickup>(`/public-check-in/pickup/${pickupId}/driver/${driverId}/assign-orders-to-pickup`, orders);
    }

    sendCheckInNotifications(checkInId: number): Observable<boolean> {
        return this.http.get<boolean>(`/public-check-in/${checkInId}/send-notifications`);
    }

    getValidDeliveryByContainerNumber(containerNumber: string): Observable<IDelivery> {
        return this.http.get<IDelivery>(`/public-check-in/get-valid-delivery-by-container-number/${containerNumber}`);
    }

    captureSquareOrder(checkInId: number, squarePaymentToken: string): Observable<null> {
        return this.http.post<null>(`/public-check-in/${checkInId}/capture-square-order/${squarePaymentToken}`, null);
    }

    getSquareLocationId(pickupId: number): Observable<string> {
        return this.http.get<string>(`/public-check-in/pickup/${pickupId}/square-location-id`);
    }

    private _checkedInDriver: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
    public get checkedInDriver$(): BehaviorSubject<IUser> {
        return this._checkedInDriver;
    }

    private _checkedInPickup: BehaviorSubject<IPickup> = new BehaviorSubject<IPickup>(null);
    public get checkedInPickup$(): BehaviorSubject<IPickup> {
        return this._checkedInPickup;
    }

    private _checkedInOrder: BehaviorSubject<IOrder> = new BehaviorSubject<IOrder>(null);
    public get checkedInOrder$(): BehaviorSubject<IOrder> {
        return this._checkedInOrder;
    }

    private _driverCreatedCheckIn: BehaviorSubject<ICheckIn> = new BehaviorSubject<ICheckIn>(null);
    public get driverCreatedCheckIn$(): BehaviorSubject<ICheckIn> {
        return this._driverCreatedCheckIn;
    }

    private _driverSelectedOrders: BehaviorSubject<IOrder[]> = new BehaviorSubject<IOrder[]>(null);
    public get driverSelectedOrders$(): BehaviorSubject<IOrder[]> {
        return this._driverSelectedOrders;
    }
}
