<div *ngIf="driver && editDriverForm && !checkedIn" class="text-center public-responsive">
    <h1>Enter Container # or Trailer #</h1>
    <br />
    <p class="f-20">You can drop off one container/trailer at a time</p>
    <br />
    <div class="row">
        <div *ngIf="!checkedIn" class="col-md-12 col-sm-12">
            <form [formGroup]="editDriverForm" class="form-contents" (ngSubmit)="onSubmit()">
                <div>
                    <div>
                        <label for="firstName">First Name:</label>
                        <input type="text" id="firstName" formControlName="firstName" required>
                        <div *ngIf="editDriverForm.get('firstName').errors?.required && editDriverForm.get('firstName').touched"
                            class="error-message">
                            First Name is required.
                        </div>
                        <div *ngIf="editDriverForm.get('firstName').errors?.forbiddenName && editDriverForm.get('firstName').touched"
                            class="error-message">
                            First Name cannot contain numbers or special characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="lastName">Last Name:</label>
                        <input type="text" id="lastName" formControlName="lastName" required>
                        <div *ngIf="editDriverForm.get('lastName').errors?.required && editDriverForm.get('lastName').touched"
                            class="error-message">
                            Last Name is required.
                        </div>
                        <div *ngIf="editDriverForm.get('lastName').errors?.forbiddenName && editDriverForm.get('lastName').touched"
                            class="error-message">
                            Last Name cannot contain numbers or special characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="licensePlateNumber">Trailer License Plate #:</label>
                        <input type="text" id="licensePlateNumber" formControlName="licensePlateNumber" required>
                        <div *ngIf="editDriverForm.get('licensePlateNumber').invalid && editDriverForm.get('licensePlateNumber').touched"
                            class="error-message">
                            Trailer License Plate # is required.
                        </div>
                        <div *ngIf="editDriverForm.get('licensePlateNumber').errors?.forbiddenNumber && editDriverForm.get('licensePlateNumber').touched"
                            class="error-message">
                            Trailer License Plate # may only contain alphanumeric characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="driverLicenseNumber">Driver License # (optional):</label>
                        <input type="text" id="driverLicenseNumber" formControlName="driverLicenseNumber">
                        <div *ngIf="editDriverForm.get('driverLicenseNumber').errors?.forbiddenNumber && editDriverForm.get('driverLicenseNumber').touched"
                            class="error-message">
                            Driver License # may only contain alphanumeric characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="containerNumber">Container # / Trailer #:</label>
                        <input type="text" id="containerNumber" formControlName="containerNumber" required>
                        <div *ngIf="editDriverForm.get('containerNumber').invalid && editDriverForm.get('containerNumber').touched"
                            class="error-message">
                            Container # / Trailer # is required.
                        </div>
                        <div *ngIf="editDriverForm.get('containerNumber').errors?.forbiddenNumber && editDriverForm.get('containerNumber').touched"
                            class="error-message">
                            Container # / Trailer # may only contain alphanumeric characters.
                        </div>
                    </div>
                    <br>
                    <div class="float-md-left">
                        <button
                            [disabled]="editDriverForm.invalid || doubleClickIsDisabled"
                            type="submit" 
                            class="btn btn-primary">
                            Done
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver"></app-driver-check-in-support-button>
    <br />
</div>

<div *ngIf="checkedIn" class="text-center public-responsive">
    <h1>Thanks!</h1>
    <i class="fa fa-lg fa-check-circle check-circle-success"></i>
    <br />
    <h2>You've been checked in.</h2>
    <br />
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <div  class="col-md-12 col-sm-12">
                <p class="f-10">Please wait in your truck.</p>
                <p class="f-10">We'll text you at <b><a href="tel:2151233456">(215) 123-3456</a></b> with next steps. Please keep your phone handy.</p>
                <p class="f-10">If you need assistance, please call <b><a href="tel:8567423142">(856) 742-3142</a></b> or enter the trailer.</p>
            </div>
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver"></app-driver-check-in-support-button>
    <br />
</div>
