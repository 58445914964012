import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { OrderService } from '../../orders/order.service';
import { PickupService } from '../../model/shared-entities/pickups/pickup.service';
import { IPickup } from '../../model/interfaces/pickup';
import { UserRoles } from '../../model/UserRoles';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ApplePay, Card, GooglePay, Square } from '@square/web-sdk';
import { environment } from '../../environments/environment';
import { ScriptLoaderService } from '../../common/services/script-loader-service';

// Extend the Window interface to include the Square global object
declare global {
    interface Window {
        Square?: Square;
    }
}

@Component({
    selector: 'app-pickup-pre-payment',
    templateUrl: './pickup-pre-payment.component.html',
    styleUrls: ['./pickup-pre-payment.component.css'],
})
export class PickupPrePaymentComponent implements OnInit, AfterViewInit {
    // Payment container elements
    @ViewChild('creditCardContainer') creditCardContainer: ElementRef<HTMLDivElement>;
    @ViewChild('applePayContainer') applePayContainer: ElementRef<HTMLDivElement>;

    // Payment button elements
    @ViewChild('creditCardButton') creditCardButton: ElementRef<HTMLButtonElement>;
    @ViewChild('applePayButton') applePayButton: ElementRef<HTMLButtonElement>;
    @ViewChild('googlePayButton') googlePayButton: ElementRef<HTMLDivElement>;
    
    pickup: IPickup;
    pickupId: number;
    remainingBalance: number;

    selectedPaymentMethod = 'creditCard';
    twicFeeLabel = 'TWIC Fee';
    googlePayButtonId = 'gpay-button-online-api-id';
    loading = true;
    applePayNotLoaded = false;
    redirectInProcess = false;
    env = environment;
    squareLocationId: string;

    constructor(
        private pickupService: PickupService,
        private orderService: OrderService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private scriptLoader: ScriptLoaderService,
    ) { }

    ngOnInit(): void {
        
        this.pickupId = +this.route.snapshot.paramMap.get('pickupId');
        this.initSubscriptions();
    }

    initSubscriptions(): void {
        const currentUser = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(currentUser.AuthId).subscribe(authUserRole => {
            if (authUserRole !== UserRoles.Carrier) {
                this.redirectInProcess = true;
                void this.router.navigate(['/create-requests/manage-pickups']);
                return;
            }
        });

        this.pickupService.getById(this.pickupId).subscribe({
            next: (pickup) => {
                if (!pickup) {
                    this.redirectInProcess = true;
                    void this.router.navigate(['/create-requests/manage-pickups']);
                    return;
                }

                this.pickup = pickup;
                this.pickupService.selectedPickup$.next(pickup);
            }
        });

        this.pickupService.getRemainingBalance(this.pickupId).subscribe({
            next: (remainingBalance) => {
                if (this.remainingBalance <= 0) {
                    this.redirectInProcess = true;
                    this.notificationsService.error('The remaining balance for this pickup is $0.00. No payment is required.');
                    void this.router.navigate(['/create-requests/manage-pickups']);
                    return;
                }

                this.remainingBalance = remainingBalance;
            }
        });

        this.pickupService.getSquareLocationId(this.pickupId).subscribe({
            next: (squareLocationId) => {
                this.squareLocationId = squareLocationId;
                if (!this.squareLocationId) {
                    this.redirectInProcess = true;
                    this.notificationsService.error('The selected pickup does not have a office with a Square Location ID. Please contact support.');
                    void this.router.navigate(['/create-requests/manage-pickups']);
                    return;
                }
            }
        });

        this.pickupService.isOutsidePrePaymentTimeframe(this.pickupId).subscribe({
            next: (isOutsidePrePaymentTimeframe) => {
                if (isOutsidePrePaymentTimeframe) {
                    this.redirectInProcess = true;
                    this.notificationsService.error('The ability to pre-pay for this pickup is outside the pre-payment time frame.');
                    void this.router.navigate(['/create-requests/manage-pickups']);
                    return;
                }
            }
        });
    }

    async ngAfterViewInit() {
        // Wait for the redirect logic to complete before loading the Square script
        await new Promise(resolve => setTimeout(resolve, 500));
        if (this.redirectInProcess) {
            this.loading = false;
            return;
        }
        // Load the Square payment script and initialize the credit card payment
        await this.loadSquareScript();
        await this.initCreditCardPayment();
    }

    async loadSquareScript() {
        try {
            await this.scriptLoader.loadScript(this.env.squareWebPaymentsScriptUrl);
        } catch (error) {
            this.notificationsService.error('There was an error loading the Square payment script.');
        }
    }

    async initCreditCardPayment(): Promise<void> {
        // Wait for the Square global object to be available
        this.loading = true;
        await new Promise(resolve => setTimeout(resolve, 500));

        // Initialize the Square payments object and attach the card container
        const payments = window.Square.payments(environment.squareApplicationId, this.squareLocationId);
        
        // Attempt to attach credit card components to the DOM
        let creditCard: Card = null;
        try {
            creditCard = await payments.card();
            await creditCard.attach(this.creditCardContainer.nativeElement);
        } catch (error) {
            this.notificationsService.error('There was an error loading the credit card payment script.');
        }

        // Return if credit card did not load successfully
        this.loading = false;
        if (!creditCard) {
            return;
        }

        // Add an event listener to the card button to handle the payment
        this.creditCardButton.nativeElement.addEventListener('click', () => {
            void this.handlePayment(creditCard);
        });
    }

    async initApplePayPayment(): Promise<void> {
        // Wait for the Square global object to be available
        this.loading = true;
        await new Promise(resolve => setTimeout(resolve, 500));

        // Initialize the Square payments object and attach the card container
        const payments = window.Square.payments(environment.squareApplicationId, this.squareLocationId);
        const paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: this.remainingBalance.toString(),
                label: 'Total',
            },
        });

        // Attempt to attach ApplePay components to the DOM
        let applePay: ApplePay = null;
        try {
            const card = await payments.card();
            await card.attach(this.applePayContainer.nativeElement);
            applePay = await payments.applePay(paymentRequest);
            this.loading = false;
        } catch (error) {
            this.notificationsService.error('There was an error loading the Apple Pay payment script.');
            this.notificationsService.error(error as string);
            this.loading = false;
        }

        // Return if Apple Pay did not load successfully
        if (!applePay) {
            this.applePayNotLoaded = true;
            return;
        }

        // Add an event listener to the card button to handle the payment
        this.applePayButton.nativeElement.addEventListener('click', () => {
            void this.handlePayment(applePay);
        });
    }

    async initGooglePayPayment(): Promise<void> {
        // Wait for the Square global object to be available
        this.loading = true;
        await new Promise(resolve => setTimeout(resolve, 500));

        // Initialize the Square payments object and attach the card container
        const payments = window.Square.payments(environment.squareApplicationId, this.squareLocationId);
        const paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: this.remainingBalance.toFixed(2),
                label: 'Total',
            },
        });

        // Attempt to attach GooglePay components to the DOM
        let googlePay: GooglePay = null;
        try {
            googlePay = await payments.googlePay(paymentRequest);
            await googlePay.attach(this.googlePayButton.nativeElement);
        } catch (error) {
            this.notificationsService.error('There was an error loading the Google Pay payment script.');
        }
        
        // Return if Google Pay did not load successfully
        this.loading = false;
        if (!googlePay) {
            return;
        }

        // Add an event listener to the card button to handle the payment
        this.googlePayButton.nativeElement.addEventListener('click', () => {
            void this.handlePayment(googlePay);
        });
    }

    async handlePayment(payment: Card | ApplePay | GooglePay): Promise<void> {
        try {
            const result = await payment.tokenize();
            const status = result.status.toString();
            if (status === 'OK') {
                this.pickupService.captureSquareOrder(this.pickup.Id, result.token).subscribe({
                    next: () => {
                        this.orderPaid();
                    },
                    error: () => {
                        this.notificationsService.error('There was an error capturing the payment.');
                    },
                });
                return;
            } 
            
            if (status === 'Cancel') {
                this.notificationsService.info('The payment was canceled.');
                return;
            }

            let errorMessage = `Tokenization failed with status: ${result.status}`;
            if (result.errors) {
                errorMessage += ` and errors: ${JSON.stringify(result.errors)}`;
            }
            this.notificationsService.error(errorMessage);
        } catch (error) {
            this.notificationsService.error('There was an error processing the payment.');
        }
    }

    orderPaid(): void {
        this.notificationsService.success(`Your payment has been successfully processed in the amount of $${this.remainingBalance.toFixed(2)} for pickup ${this.pickup.PickupNumber}.`);
        void this.router.navigate(['/create-requests/manage-pickups']);            
    }

    determineApplePayButtonText(): string {
        if (this.applePayNotLoaded) {
            return 'Apple Pay is not available';
        }

        if (this.loading)
            return 'Loading...';

        return 'Pay';
    }
}
