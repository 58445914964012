import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IOffice } from '../interfaces/office';
import { ILocation } from '../interfaces/location';

export interface IOfficeDynamicControlsParameters {
    formGroup?: string;
    locations?: ILocation[];
}

export class OfficeDynamicControls {

    formGroup: string;
    locations?: ILocation[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private office?: IOffice, additionalParameters?: IOfficeDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Office';
        this.locations = additionalParameters && additionalParameters.locations || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('Archived') && this.office.Archived != null ? this.office.Archived : false,
            }),
            CheckInRadiusLimit: new DynamicField({
                formGroup: this.formGroup,
                label: 'Check In Radius Limit',
                name: 'CheckInRadiusLimit',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.CheckInRadiusLimit || null,
            }),
            DaeOffice: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dae Office',
                name: 'DaeOffice',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DaeOffice') && this.office.DaeOffice != null ? this.office.DaeOffice : false,
            }),
            DefaultEndTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default End Time',
                name: 'DefaultEndTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.office && this.office.hasOwnProperty('DefaultEndTime') && this.office.DefaultEndTime != null ? this.office.DefaultEndTime.toString() : '',
            }),
            DefaultStartTime: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Start Time',
                name: 'DefaultStartTime',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.office && this.office.hasOwnProperty('DefaultStartTime') && this.office.DefaultStartTime != null ? this.office.DefaultStartTime.toString() : '',
            }),
            DefaultWeekdayFri: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Fri',
                name: 'DefaultWeekdayFri',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayFri') && this.office.DefaultWeekdayFri != null ? this.office.DefaultWeekdayFri : true,
            }),
            DefaultWeekdayMon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Mon',
                name: 'DefaultWeekdayMon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayMon') && this.office.DefaultWeekdayMon != null ? this.office.DefaultWeekdayMon : true,
            }),
            DefaultWeekdaySat: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Sat',
                name: 'DefaultWeekdaySat',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DefaultWeekdaySat') && this.office.DefaultWeekdaySat != null ? this.office.DefaultWeekdaySat : true,
            }),
            DefaultWeekdaySun: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Sun',
                name: 'DefaultWeekdaySun',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DefaultWeekdaySun') && this.office.DefaultWeekdaySun != null ? this.office.DefaultWeekdaySun : true,
            }),
            DefaultWeekdayThu: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Thu',
                name: 'DefaultWeekdayThu',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayThu') && this.office.DefaultWeekdayThu != null ? this.office.DefaultWeekdayThu : true,
            }),
            DefaultWeekdayTue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Tue',
                name: 'DefaultWeekdayTue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayTue') && this.office.DefaultWeekdayTue != null ? this.office.DefaultWeekdayTue : true,
            }),
            DefaultWeekdayWed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Default Weekday Wed',
                name: 'DefaultWeekdayWed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayWed') && this.office.DefaultWeekdayWed != null ? this.office.DefaultWeekdayWed : true,
            }),
            DriverCheckInReminderLimit: new DynamicField({
                formGroup: this.formGroup,
                label: 'Driver Check In Reminder Limit',
                name: 'DriverCheckInReminderLimit',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.DriverCheckInReminderLimit || null,
            }),
            LocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Location',
                name: 'LocationId',
                options: this.locations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.LocationId || null,
            }),
            NonTwicFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Non Twic Fee',
                name: 'NonTwicFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.NonTwicFee || 0,
            }),
            NoOfPickupsPerHour: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Of Pickups Per Hour',
                name: 'NoOfPickupsPerHour',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.NoOfPickupsPerHour || 0,
            }),
            SquareLocationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Square Location',
                name: 'SquareLocationId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.office && this.office.hasOwnProperty('SquareLocationId') && this.office.SquareLocationId != null ? this.office.SquareLocationId.toString() : '',
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.office && this.office.hasOwnProperty('Title') && this.office.Title != null ? this.office.Title.toString() : '',
            }),
            TwicFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Twic Fee',
                name: 'TwicFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.office && this.office.TwicFee || 0,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.office && this.office.hasOwnProperty('Archived') && this.office.Archived != null ? this.office.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CheckInRadiusLimit: new DynamicLabel({
                label: 'Check In Radius Limit',
                value: this.office && this.office.CheckInRadiusLimit || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            DaeOffice: new DynamicLabel({
                label: 'Dae Office',
                value: this.office && this.office.hasOwnProperty('DaeOffice') && this.office.DaeOffice != null ? this.office.DaeOffice : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultEndTime: new DynamicLabel({
                label: 'Default End Time',
                value: this.office && this.office.hasOwnProperty('DefaultEndTime') && this.office.DefaultEndTime != null ? this.office.DefaultEndTime.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultStartTime: new DynamicLabel({
                label: 'Default Start Time',
                value: this.office && this.office.hasOwnProperty('DefaultStartTime') && this.office.DefaultStartTime != null ? this.office.DefaultStartTime.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayFri: new DynamicLabel({
                label: 'Default Weekday Fri',
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayFri') && this.office.DefaultWeekdayFri != null ? this.office.DefaultWeekdayFri : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayMon: new DynamicLabel({
                label: 'Default Weekday Mon',
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayMon') && this.office.DefaultWeekdayMon != null ? this.office.DefaultWeekdayMon : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdaySat: new DynamicLabel({
                label: 'Default Weekday Sat',
                value: this.office && this.office.hasOwnProperty('DefaultWeekdaySat') && this.office.DefaultWeekdaySat != null ? this.office.DefaultWeekdaySat : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdaySun: new DynamicLabel({
                label: 'Default Weekday Sun',
                value: this.office && this.office.hasOwnProperty('DefaultWeekdaySun') && this.office.DefaultWeekdaySun != null ? this.office.DefaultWeekdaySun : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayThu: new DynamicLabel({
                label: 'Default Weekday Thu',
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayThu') && this.office.DefaultWeekdayThu != null ? this.office.DefaultWeekdayThu : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayTue: new DynamicLabel({
                label: 'Default Weekday Tue',
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayTue') && this.office.DefaultWeekdayTue != null ? this.office.DefaultWeekdayTue : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefaultWeekdayWed: new DynamicLabel({
                label: 'Default Weekday Wed',
                value: this.office && this.office.hasOwnProperty('DefaultWeekdayWed') && this.office.DefaultWeekdayWed != null ? this.office.DefaultWeekdayWed : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DriverCheckInReminderLimit: new DynamicLabel({
                label: 'Driver Check In Reminder Limit',
                value: this.office && this.office.DriverCheckInReminderLimit || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            LocationId: new DynamicLabel({
                label: 'Location',
                value: getMetaItemValue(this.locations as unknown as IMetaItem[], this.office && this.office.hasOwnProperty('LocationId') ? this.office.LocationId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            NonTwicFee: new DynamicLabel({
                label: 'Non Twic Fee',
                value: this.office && this.office.NonTwicFee || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            NoOfPickupsPerHour: new DynamicLabel({
                label: 'No Of Pickups Per Hour',
                value: this.office && this.office.NoOfPickupsPerHour || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            SquareLocationId: new DynamicLabel({
                label: 'Square Location',
                value: this.office && this.office.hasOwnProperty('SquareLocationId') && this.office.SquareLocationId != null ? this.office.SquareLocationId.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Title: new DynamicLabel({
                label: 'Title',
                value: this.office && this.office.hasOwnProperty('Title') && this.office.Title != null ? this.office.Title.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            TwicFee: new DynamicLabel({
                label: 'Twic Fee',
                value: this.office && this.office.TwicFee || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
        };

    }
}
