import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { formatDate } from '@angular/common';
import { IPickupPaymentDetail } from '../../model/interfaces/pickup-payment-detail';

export class PickupPaymentDetailEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return detail?.CheckIn?.Pickup?.PickupNumber ?? detail?.Pickup?.PickupNumber; 
                    },
                    name: 'Pickup #',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'CheckIn.Pickup.PickupNumber ?? Pickup.PickupNumber',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return formatDate(detail.DateCreated, 'medium', 'en-US');
                    },
                    name: 'Date Created',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'DateCreated',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return `$${detail?.Amount}`;
                    },
                    name: 'Amount Charged',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Amount',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        const checkInDriver = detail?.CheckIn?.CheckInUser;
                        if (checkInDriver?.Id > 0) 
                            return `${checkInDriver?.FirstName ?? ''} ${checkInDriver?.LastName ?? ''}`;

                        const pickupDriver = detail?.Pickup?.Driver;
                            return `${pickupDriver?.FirstName ?? ''} ${pickupDriver?.LastName ?? ''}`;
                    },
                    name: 'Driver',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'CheckIn.CheckInUser.FirstName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return `${detail?.Pickup?.Carrier?.Name ?? ''}`;
                    },
                    name: 'Carrier',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'CheckIn.CheckInUser.FirstName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        const order = detail?.Pickup?.Orders.find(order => order.Warehouse?.OfficeId > 0);
                        if (!order)
                            return '';
                        return `${order.Warehouse?.Office?.Title ?? ''}`;
                    },
                    name: 'Office',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Pickup.Orders.FirstOrDefault().Warehouse.Office.Title',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        const order = detail?.Pickup?.Orders.find(order => order.Warehouse?.OfficeId > 0);
                        if (!order)
                            return '';
                        return `${order.Warehouse?.Office?.SquareLocationId ?? ''}`;
                    },
                    name: 'Square Location ID',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Pickup.Orders.FirstOrDefault().Warehouse.Office.SquareLocationId',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return detail.IsSuccessful ? 'Successful' : 'Unsuccessful';
                    },
                    name: 'Payment Status',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'IsSuccessful',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return `${detail?.CreatedBy?.FirstName ?? ''} ${detail?.CreatedBy?.LastName ?? ''}`;
                    },
                    name: 'Paid By',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'CreatedBy.FirstName',
                    },
                }),
            ],
        };
        
        super(listConfig);
    }
}
