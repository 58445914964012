<div class="text-center public-responsive">
    <h1>Payment</h1>
    <br />
    <div *ngIf="squareLocationId" class="row">
        <div class="col-md-12 col-sm-12">
            <div class="form-group">
                <p class="fee-label"><b>Fee:</b> {{ remainingBalance | currency:'USD' }}</p>
                <p class="fee-label"><b>Payment Method:</b></p>
                <div>
                    <label class="radio-inline">
                        <input type="radio" name="paymentMethod" value="creditCard" [(ngModel)]="selectedPaymentMethod"
                            (change)="initCreditCardPayment()" [disabled]="loading">
                        Credit Card
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="paymentMethod" value="applePay" [(ngModel)]="selectedPaymentMethod"
                            (change)="initApplePayPayment()" [disabled]="loading">
                        Apple Pay
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="paymentMethod" value="googlePay" [(ngModel)]="selectedPaymentMethod"
                            (change)="initGooglePayPayment()" [disabled]="loading">
                        Google Pay
                    </label>
                </div>
            </div>

            <div [ngSwitch]="selectedPaymentMethod">
                <div *ngSwitchCase="'creditCard'">
                    <div id="payment-form">
                        <div #creditCardContainer id="credit-card-container"></div>
                        <button #creditCardButton id="credit-card-button" type="button"
                            class="btn btn-raised btn-success" [disabled]="loading">
                            {{ loading ? 'Loading...' : 'Pay' }}
                        </button>
                    </div>
                </div>
                <div *ngSwitchCase="'applePay'">
                    <div id="payment-form">
                        <div #applePayContainer id="apple-pay-container"></div>
                        <button #applePayButton id="apple-pay-button" type="button" 
                            class="btn btn-raised btn-success" [disabled]="loading || applePayNotLoaded">
                            {{ determineApplePayButtonText() }}
                        </button>
                    </div>
                </div>
                <div *ngSwitchCase="'googlePay'">
                    <div id="payment-form">
                        <button type="button" class="btn btn-raised btn-success" *ngIf="loading" disabled>
                            Loading...
                        </button>
                        <div #googlePayButton id="google-pay-button">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!loading && !squareLocationId" class="row">
        <div class="col-md-12 col-sm-12">
            <div class="alert alert-danger" role="alert" style="display: inline-block;">
                The selected pickup does not have a office with a Square Location ID. Please contact support.
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-sm-12">
            <span class="go-back-link--wrapper">
                <div class="go-back-link">Need to check-in with a different number? Please <a (click)="goBack()">click here</a> to return to the previous page.
                </div>
            </span>
        </div>
    </div>
    <app-driver-check-in-support-button [driver]="driver" [pickup]="pickup"></app-driver-check-in-support-button>
    <br />
</div>
