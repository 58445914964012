import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
    IDynamicFieldType,
    IDynamicField,
} from '@mt-ng2/dynamic-form';

import { IOffice } from '../interfaces/office';
import { OfficeDynamicControls, IOfficeDynamicControlsParameters } from '../form-controls/office.form-controls';

export class OfficeDynamicControlsPartial extends OfficeDynamicControls {
    constructor(officePartial?: IOffice, additionalParameters?: IOfficeDynamicControlsParameters) {
        super(officePartial, additionalParameters);

        (<DynamicField>this.Form.DaeOffice).labelHtml = '<label>DAE Office</label>';
        (<DynamicField>this.Form.DaeOffice).label = 'DAE Office';
        (<DynamicField>this.View.DaeOffice).labelHtml = '<label>DAE Office</label>';
        (<DynamicField>this.View.DaeOffice).label = 'DAE Office';
        if (officePartial) {
            (<DynamicField>this.Form.DaeOffice).value = (<any>officePartial).DaeOffice;
            (<DynamicField>this.View.DaeOffice).value = (<any>officePartial).DaeOffice;
        }

        (<DynamicField>this.View.LocationId).labelHtml = '<label>Gate Location</label>';
        (<DynamicField>this.View.LocationId).label = 'Gate Location';
        (<DynamicField>this.Form.LocationId).labelHtml = '<label>Gate Location</label>';
        (<DynamicField>this.Form.LocationId).label = 'Gate Location';

        (<DynamicField>this.View.SquareLocationId).labelHtml = '<label>Square Location ID</label>';
        (<DynamicField>this.View.SquareLocationId).label = 'Square Location ID';
        (<DynamicField>this.Form.SquareLocationId).labelHtml = '<label>Square Location ID</label>';
        (<DynamicField>this.Form.SquareLocationId).label = 'Square Location ID';

        this.Form.DefaultStartTime = new DynamicField({
            formGroup: 'Office',
            label: 'Default Start Time',
            name: 'DefaultStartTime',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Timepicker,
                timepickerOptions: {
                    meridian: true,
                },
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value:
                officePartial && officePartial.hasOwnProperty('DefaultStartTime') && officePartial.DefaultStartTime
                    ? officePartial.DefaultStartTime
                    : '',
        } as IDynamicField);

        this.Form.DefaultEndTime = new DynamicField({
            formGroup: 'Office',
            label: 'Default End Time',
            name: 'DefaultEndTime',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Timepicker,
                timepickerOptions: {
                    meridian: true,
                },
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value:
                officePartial && officePartial.hasOwnProperty('DefaultEndTime') && officePartial.DefaultEndTime ? officePartial.DefaultEndTime : '',
        } as IDynamicField);
        
        this.Form.CheckInRadiusLimit = new DynamicField({
            formGroup: this.formGroup,
            label: 'Check In Radius Limit (in miles)',
            name: 'CheckInRadiusLimit',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.min(0)],
            validators: { min: 0 },
            value: officePartial && officePartial.CheckInRadiusLimit || null,
        } as IDynamicField);

        this.Form.DriverCheckInReminderLimit = new DynamicField({
            formGroup: this.formGroup,
            label: 'Driver Check In Reminder Limit (in hours)',
            name: 'DriverCheckInReminderLimit',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.min(0)],
            validators: { min: 0 },
            value: officePartial && officePartial.DriverCheckInReminderLimit || null,
        } as IDynamicField);

        (<DynamicField>this.View.CheckInRadiusLimit).label = 'Check In Radius Limit (in miles)';
        (<DynamicField>this.View.DriverCheckInReminderLimit).label = 'Driver Check In Reminder Limit (in hours)';

        this.Form.NonTwicFee = new DynamicField({
            formGroup: this.formGroup,
            label: 'Security Gate Fee Non TWIC (in USD)',
            name: 'NonTwicFee',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Currency,
                scale: 2,
            }),
            validation: [Validators.min(0)],
            validators: { min: 0 },
            value: officePartial && officePartial.NonTwicFee || 0,
        } as IDynamicField);

        this.Form.TwicFee = new DynamicField({
            formGroup: this.formGroup,
            label: 'Security Gate Fee TWIC (in USD)',
            name: 'TwicFee',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Currency,
                scale: 2,
            }),
            validation: [Validators.min(0)],
            validators: { min: 0 },
            value: officePartial && officePartial.TwicFee || 0,
        } as IDynamicField);
        
        (<DynamicField>this.View.NonTwicFee).label = 'Security Gate Fee Non TWIC (in USD)';
        (<DynamicField>this.View.TwicFee).label = 'Security Gate Fee TWIC (in USD)';
        
        (<DynamicField>this.View.NonTwicFee).value = `$${officePartial?.NonTwicFee?.toFixed(2) ?? 0.00}`;
        (<DynamicField>this.View.TwicFee).value = `$${officePartial?.TwicFee?.toFixed(2) ?? 0.00}`;

        (<DynamicField>this.View.DefaultWeekdayMon).labelHtml = '<b>Every Monday</b>';
        (<DynamicField>this.View.DefaultWeekdayTue).labelHtml = '<b>Every Tuesday</b>';
        (<DynamicField>this.View.DefaultWeekdayWed).labelHtml = '<b>Every Wednesday</b>';
        (<DynamicField>this.View.DefaultWeekdayThu).labelHtml = '<b>Every Thursday</b>';
        (<DynamicField>this.View.DefaultWeekdayFri).labelHtml = '<b>Every Friday</b>';
        (<DynamicField>this.View.DefaultWeekdaySat).labelHtml = '<b>Every Saturday</b>';
        (<DynamicField>this.View.DefaultWeekdaySun).labelHtml = '<b>Every Sunday</b>';

        (<DynamicField>this.Form.DefaultWeekdayMon).labelHtml = '<label>Every Monday</label>';
        (<DynamicField>this.Form.DefaultWeekdayTue).labelHtml = '<label>Every Tuesday</label>';
        (<DynamicField>this.Form.DefaultWeekdayWed).labelHtml = '<label>Every Wednesday</label>';
        (<DynamicField>this.Form.DefaultWeekdayThu).labelHtml = '<label>Every Thursday</label>';
        (<DynamicField>this.Form.DefaultWeekdayFri).labelHtml = '<label>Every Friday</label>';
        (<DynamicField>this.Form.DefaultWeekdaySat).labelHtml = '<label>Every Saturday</label>';
        (<DynamicField>this.Form.DefaultWeekdaySun).labelHtml = '<label>Every Sunday</label>';
    }
}
