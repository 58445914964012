<div *ngIf="loading" class="loading-spinner">
    <div class="spinner"></div>
</div>

<div *ngIf="!loading">
    <div class="form-wrapper">
        <div class="login-box">
            <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
        </div>
        <h1 class="form-header">Enter your contact information</h1>
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
            <div>
                <label class="input-label" for="firstName">First Name:</label>
                <input type="text" id="firstName" formControlName="firstName" required>
                <div *ngIf="registrationForm.get('firstName').errors?.required && registrationForm.get('firstName').touched"
                    class="error-message">
                    First Name is required
                </div>
                <div *ngIf="registrationForm.get('firstName').errors?.forbiddenName && registrationForm.get('firstName').touched"
                    class="error-message">
                    First Name cannot contain numbers or special characters
                </div>
            </div>
            <br>
            <div>
                <label class="input-label" for="lastName">Last Name:</label>
                <input type="text" id="lastName" formControlName="lastName" required>
                <div *ngIf="registrationForm.get('lastName').errors?.required && registrationForm.get('lastName').touched"
                    class="error-message">
                    Last Name is required
                </div>
                <div *ngIf="registrationForm.get('lastName').errors?.forbiddenName && registrationForm.get('lastName').touched"
                    class="error-message">
                    Last Name cannot contain numbers or special characters
                </div>
            </div>
            <br>
            <div>
                <label class="input-label" for="email">Email:</label>
                <input type="email" id="email" formControlName="email" required>
                <div *ngIf="registrationForm.get('email').invalid && registrationForm.get('email').touched"
                    class="error-message">
                    Please enter a valid email address
                </div>
            </div>
            <br>
            <div>
                <label class="input-label" for="phone">Phone:</label>
                <input type="text" id="phone" formControlName="phone" required>
                <div *ngIf="registrationForm.get('phone').errors?.required && registrationForm.get('phone').touched"
                    class="error-message">
                    Phone Number is required
                </div>
                <div *ngIf="registrationForm.get('phone').errors?.forbiddenNumber && registrationForm.get('phone').touched"
                    class="error-message">
                    Please enter a valid phone number
                </div>
            </div>
            <br>
            <div>
                <h4>Representation Choice:</h4>
                <div class="radio-button-wrapper">
                    <input type="radio" formControlName="radio" id="radio1" value="customer"
                        (change)="onRadioChange('customer')">
                    <label for="radio1">I represent a customer.</label>
                </div>
                <div class="radio-button-wrapper">
                    <input type="radio" formControlName="radio" id="radio2" value="carrier"
                        (change)="onRadioChange('carrier')">
                    <label for="radio2">I represent a carrier.</label>
                </div>
                <div *ngIf="selectedRadioOption">
                    <select id="dropdown" formControlName="dropdownOption">
                        <option *ngFor="let option of dropdownOptions" [value]="option.Id">{{ option.Name }}</option>
                    </select>
                </div>
            </div>
            <br>
            <button class="btn btn-success" type="submit" [disabled]="registrationForm.invalid">Register</button>
        </form>
        <br>
        <div>
            For any questions, please contact us at 215-551-2037 or email us at <a
                href="mailto: Customerservice@holtlogistics.com">Customerservice@holtlogistics.com</a>
        </div>
    </div>
</div>