<div *ngIf="loading" class="loading-spinner">
    <div class="spinner"></div>
</div>

<div *ngIf="!loading" class="text-center public-responsive">
    <h1>Enter Driver's Info</h1>
    <br />
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <form [formGroup]="newDriverForm" class="form-contents" (ngSubmit)="onSubmit()">
                <div>
                    <div>
                        <label for="firstName">First Name:</label>
                        <input type="text" id="firstName" formControlName="firstName" required>
                        <div *ngIf="newDriverForm.get('firstName').errors?.required && newDriverForm.get('firstName').touched"
                            class="error-message">
                            First Name is required.
                        </div>
                        <div *ngIf="newDriverForm.get('firstName').errors?.forbiddenName && newDriverForm.get('firstName').touched"
                            class="error-message">
                            First Name cannot contain numbers or special characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="lastName">Last Name:</label>
                        <input type="text" id="lastName" formControlName="lastName" required>
                        <div *ngIf="newDriverForm.get('lastName').errors?.required && newDriverForm.get('lastName').touched"
                            class="error-message">
                            Last Name is required.
                        </div>
                        <div *ngIf="newDriverForm.get('lastName').errors?.forbiddenName && newDriverForm.get('lastName').touched"
                            class="error-message">
                            Last Name cannot contain numbers or special characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="phone">Phone Number:</label>
                        <input type="text" id="phone" formControlName="phone" required>
                        <div *ngIf="newDriverForm.get('phone').errors?.required && newDriverForm.get('phone').touched"
                            class="error-message">
                            Phone Number is required.
                        </div>
                        <div *ngIf="newDriverForm.get('phone').errors?.forbiddenNumber && newDriverForm.get('phone').touched"
                            class="error-message">
                            Phone Number must be 10 digits long & can only contain numerical characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="carrier">Driving Company Name:</label>
                        <select id="carrier" formControlName="carrier">
                            <option *ngFor="let carrier of carriers" [value]="carrier.Id">{{ carrier.Name }}</option>
                        </select>
                    </div>
                    <br>
                    <div>
                        <label for="UsdotNumber">USDot Number:</label>
                        <input type="text" id="UsdotNumber" formControlName="UsdotNumber">
                        <div *ngIf="newDriverForm.get('UsdotNumber').errors?.forbiddenNumber && newDriverForm.get('UsdotNumber').touched"
                            class="error-message">
                            USDot Number may only contain alphanumeric characters.
                        </div>
                    </div>
                    <br>
                    <div>
                        <label for="licensePlate">License Plate:</label>
                        <input type="text" id="licensePlate" formControlName="licensePlate" required>
                        <div *ngIf="newDriverForm.get('licensePlate').invalid && newDriverForm.get('licensePlate').touched"
                            class="error-message">
                            License Plate is required.
                        </div>
                        <div *ngIf="newDriverForm.get('licensePlate').errors?.forbiddenNumber && newDriverForm.get('licensePlate').touched"
                            class="error-message">
                            License Plate may only contain alphanumeric characters.
                        </div>
                    </div>
                    <br>
                    <div class="driverNotificationOptOut-wrapper">
                        <label for="driverNotificationOptOut">Driver Notification Opt Out:</label>
                        <input type="checkbox" id="driverNotificationOptOut" formControlName="driverNotificationOptOut" class="form-check-input">
                    </div>
                </div>
            </form>
            <button (click)="onSubmit()" type="submit" class="btn btn-primary" [disabled]="newDriverForm.invalid">Continue</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            <span class="new-driver-link--wrapper">
                <div class="new-driver-link">Already Registered? Please <a [routerLink]="['/public', 'driver-check-in-info']">click here</a> to sign in.
                </div>
            </span>
        </div>
    </div>
    <br />
</div>
