<div class="row">
    <div class="col-md-7">
        <h2>Fulfillment Monitoring</h2>
    </div>
</div>

<div class="row">
    <div class="col-md-7">
        <mt-dynamic-search-filters class="pickups-list-component-filters" [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
    </div>
    <div class="col-md-5">
        <button id="pickup-header-btn-checked-in" class="btn btn-default" selected="false" (click)="filterPickupsByStatus($event)">
            Checked-In ({{numOfCheckedInPickups}})
        </button>
        <button id="pickup-header-btn-driver-notified" class="btn btn-default" selected="false" (click)="filterPickupsByStatus($event)">
            Drivers Notified ({{numOfDriverNotifiedPickups}})
        </button>
        <button id="pickup-header-btn-fulfilled" class="btn btn-success" selected="true" (click)="filterPickupsByStatus($event)">
            Fulfilled Today ({{numOfFulfilledPickups}})
        </button>
    </div>
</div>
<br>
<div class="table-responsive">
    <table class="table table-hover">
        <thead>
            <tr>
                <th (click)="orderBy($event, 'Driver.FirstName')">
                    Driver
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th *ngIf="this.isAdminUser || isCarrierUser">
                    Customer
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th *ngIf="isCustomerUser" (click)="orderBy($event, 'Carrier.Name')">
                    Driving Company
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'CheckIns.FirstOrDefault().DateCreated')">
                    Check-In Time
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'DateRequested')">
                    Scheduled Time
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'CheckIns.FirstOrDefault().DateFulfilled')">
                    Fulfilled Time
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'StatusId')">
                    Status
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'StatusId')">
                    Office
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'Orders.FirstOrDefault().Warehouse.WarehouseId')">
                    Warehouse
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'Driver.FirstName')">
                    Door
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
                <th (click)="orderBy($event, 'PickupNumber')">
                    Pickup #
                    <span>
                        <i class="fa fa-sort-asc" style="opacity: 0.4;"></i>
                        <i class="fa fa-sort-desc" style="margin-left: -12px; opacity: 0.4;"></i>
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let pickup of pickups">
                <tr class="no-pointer">
                    <td class="ellipsis">
                        <span *ngIf="pickup.Driver?.Id > 0">
                            <i class="fa fa-lg fa-circle" [ngStyle]="{ 'color': checkDriverTwicStatus(pickup.Driver) ? 'lime' : 'red' }"></i>
                            {{ pickup.Driver.FirstName + ' ' + pickup.Driver.LastName }} - {{ pickup.Driver.Phone | phoneFormat }}
                        </span>
                        <span *ngIf="!pickup.Driver?.Id">
                            N/A
                        </span>
                    </td>
                    <td *ngIf="this.isAdminUser || isCarrierUser" class="ellipsis">
                    </td>
                    <td *ngIf="isCustomerUser" class="ellipsis">
                        <span *ngIf="pickup.Carrier?.Id > 0">
                            {{ pickup.Carrier?.Name ??  ''}}
                        </span>
                    </td>
                    <td class="ellipsis">
                        {{ pickup.CheckIns[0]?.DateCreated| date: 'medium' }}
                    </td>
                    <td class="ellipsis">
                        {{ pickup.DateRequested | date: 'medium' }}
                    </td>
                    <td class="ellipsis">
                        {{ pickup.CheckIns[0]?.DateFulfilled | date: 'medium' }}
                    </td>
                    <td class="ellipsis">
                        {{ getStatusName(pickup.StatusId) }}
                    </td>
                    <td class="ellipsis">
                        <span>
                            {{ pickup.Orders[0]?.Warehouse?.Office?.Title ?? '' }}
                        </span>
                    </td>
                    <td class="ellipsis">
                        <span>
                            {{ pickup.Orders[0]?.Warehouse?.WarehouseId ?? '' }}
                        </span>
                    </td>
                    <td class="ellipsis">
                        <span>
                            {{ pickup.Orders[0]?.Warehouse?.Office?.OfficeDoors[0]?.Door ?? '' }}
                        </span>
                    </td>
                    <td class="ellipsis">
                        {{ pickup.PickupNumber }}
                    </td>
                </tr>
                <tr class="no-pointer no-border">
                    <th class="ellipsis sub-item-header">
                        <b>Order Number</b>
                    </th>
                    <td class="ellipsis sub-item-header"></td>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                    <th class="ellipsis sub-item-header"></th>
                </tr>
                <tr class="no-pointer no-border" *ngFor="let order of pickup.Orders">
                    <td class="ellipsis sub-item">
                        {{ order.OrderNumber }}
                    </td>
                    <td class="ellipsis sub-item">
                        <span *ngIf="this.isAdminUser || isCarrierUser">
                            {{ order.Customer?.Name ?? '' }}
                        </span>
                    </td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item"></td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
